import React, { createContext, useContext, useState } from 'react';

const defaultContextData: Record<string, string> = {
  select: 'opacity-100',
};

// Create a context
const ZIndexContext = createContext({
  zIndexValues: defaultContextData,
  // @ts-expect-error: function initialization is not important, it will be overwritten as soon as the provider is used
  setZIndex: function (key: string, value: string) {
    return;
  },
});

// Create a provider component to set and manage global z-index values
export function ZIndexProvider({ children }: { children: React.ReactNode }) {
  const [zIndexValues, setZIndexValues] = useState(defaultContextData);

  const setZIndex = (key: string, value: string) => {
    setZIndexValues({
      ...zIndexValues,
      [key]: value,
    });
  };

  return (
    <ZIndexContext.Provider value={{ zIndexValues, setZIndex }}>{children}</ZIndexContext.Provider>
  );
}

// Custom hook to access the z-index values and setter
export function useZIndex() {
  return useContext(ZIndexContext);
}

// Next/React Imports
import GlobalLoader from '@/components/GlobalLoader';
import { Poppins } from '@next/font/google';
import { Analytics } from '@vercel/analytics/react';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// Styles Imports
import { ZIndexProvider } from '@/contexts/ZIndex';
import '@/styles/globals.css';
import 'leaflet/dist/leaflet.css';

// Components Imports

// Set font for all app
const poppins = Poppins({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  // weight: ['400'],
});

export default function App({
  Component,
  pageProps: { ...pageProps },
}: {
  Component: NextPage;
  pageProps: object;
}) {
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const start = (url: string) => {
      // No loading screen when going back to homepage (static page)
      if (url !== '/') setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
    };
  }, [router.events]);
  return (
    <>
      <Head>
        <title>Komète OPCO 2i</title>
        <meta name="description" content="OPCO 2i par KYU Associés" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        {/* <script>L_DISABLE_3D = true;</script> */}
      </Head>
      <main className={`${poppins.className} container min-h-screen min-w-full bg-[#f2f4f9]`}>
        <ZIndexProvider>{loading ? <GlobalLoader /> : <Component {...pageProps} />}</ZIndexProvider>
        <Analytics />
      </main>
    </>
  );
}
